import React, { FunctionComponent, useEffect, useState } from 'react'
import styled from 'styled-components'
import SvgLogo from '../generated/svg/logo'
import { RestoreScroll } from './restore-scroll'
import classNames from 'classnames'
import { Menu } from './menu'

const Outer = styled.div`
  position: fixed;
  padding: 3.75rem 3.75rem 0 2.5rem;
  width: 100%;
  left: 0;
  top: 0;
  box-sizing: border-box;
  mix-blend-mode: difference;
  z-index: 100;
  color: var(--header-color, var(--black));
  transition: opacity 0.4s, transform 0.4s;
  pointer-events: none;
  body:has(.l-container) & {
    color: #000;
  }
  @media (max-width: 40rem){
    padding: 1.25rem 1.25rem 0 1.25rem;
  }
  &.hide {
    transform: translateY(-100%);
    opacity: 0;
  }
`

const Inner = styled.div`
  display: flex;
`

const LogoContainer = styled.a`
  display: block;
  margin-right: auto;
  pointer-events: initial;
  transition: opacity 0.4s;
  &.hide {
    opacity: 0;
    pointer-events: none;
  }
  svg {
    display: block;
    height: 5.563rem;
    width: auto;
    @media (max-width: 40rem){
      height: 4.188rem;
    }
  }
`

const Navigator = styled.div`
  display: flex;
  flex-direction: column;
  margin: -0.5rem 0;
  pointer-events: initial;
  & + & {
    margin-left: 3rem;
  }
  @media (max-width: 40rem){
    display: none;
  }
`

const Item = styled.a`
  display: block;
  padding: 0.5rem 0;
  font-family: 'Forum', serif;
  font-size: 0.9375rem;
  position: relative;
  width: max-content;
  &::after {
    content: '';
    display: block;
    bottom: 0;
    left: 0;
    width: 0;
    height: 1px;
    background-color: currentColor;
    transition: width 0.4s;
  }
  &:hover {
    &::after {
      width: 100%;
    }
  }
`

const Button = styled.div`
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 1rem;
  z-index: 102;
  position: relative;
  cursor: pointer;
  display: none;
  pointer-events: all;
  transition: color 0.4s;
  @media (max-width: 40rem){
    display: flex;
  }
  &.open {
    color: white;
  }
  &::before,
  &::after {
    content: '';
    display: block;
    height: 1px;
    width: 2.25rem;
    margin: -0.5px 0;
    background-color: currentColor;
    transition: transform 0.4s;
  }

  &::before {
    transform: translateY(-0.5rem);
  }
  &.open::before {
    transform: rotate(-30deg);
  }
  &.after {
    transform: translateY(0.5rem);
  }
  &.open::after {
    transform: rotate(30deg);
  }
`

type HeaderProps = {

}
export const Header: FunctionComponent<HeaderProps> = () => {
  const [hide, setHide] = useState(false)
  const [open, setOpen] = useState(false)
  useEffect(() => {
    let lastScrollY = window.scrollY
    const listener = () => {
      const scrollY = window.scrollY
      if(scrollY === 0 || scrollY < lastScrollY){
        setHide(false)
      } else {
        setHide(true)
      }
      lastScrollY = scrollY
    }
    listener()
    window.addEventListener('scroll', listener)
    return () => {
      window.removeEventListener('scroll', listener)
    }
  }, [])
  return <>
    <Outer className={classNames({hide: !open && hide})}>
      <RestoreScroll />
      <Inner>
        <LogoContainer href="/" className={classNames({hide: open})}>
          <SvgLogo />
        </LogoContainer>
        <Navigator>
          <Item href="/shop/products">PRODUCTS</Item>
          <Item href="/shop/information_categories/topics">TOPICS</Item>
          <Item href="/shop/faq">FAQ</Item>
          <Item href="/shop/contact">CONTACT</Item>
        </Navigator>
        <Navigator>
          <Item href="/shop/customers/sign_in">LOGIN</Item>
          <Item href="/shop/cart">CART(0)</Item>
        </Navigator>
        <Button onClick={() => setOpen(v => !v)} className={classNames({open})} />
      </Inner>
    </Outer>
    <Menu open={open} />
  </>
}