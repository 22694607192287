import * as React from "react";
import type { SVGProps } from "react";
const SvgXIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
    <path
      d="M14.188 10.162 22.75 0h-2.029l-7.434 8.824L7.349 0H.5l8.98 13.343L.5 24h2.03l7.85-9.318L16.651 24H23.5zm-2.78 3.299-.909-1.329L3.26 1.56h3.117l5.842 8.532.91 1.328 7.593 11.091h-3.116l-6.197-9.05z"
      fill="currentcolor"
      strokeWidth="0px"
    />
  </svg>
);
export default SvgXIcon;
