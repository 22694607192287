import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import SvgXIcon from '../generated/svg/x-icon'
import SvgInstagramIcon from '../generated/svg/instagram-icon'
import SvgLogo from '../generated/svg/logo'
import classNames from 'classnames'

const Outer = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: none;
  background-color: var(--black);
  padding: var(--page-margin);
  box-sizing: border-box;
  color: white;
  pointer-events: all;
  z-index: 99;
  flex-direction: column;
  transition: opacity 0.8s;
  opacity: 0;
  pointer-events: none;
  @media (max-width: 40rem){
    display: flex;
  }
  &.open {
    opacity: 1;
    pointer-events: all;
  }
`

const SnsContainer = styled.div`
  display: flex;
  margin: 0 -0.75rem;
`

const SnsItem = styled.a`
  display: block;
  padding: 0.75rem;
  svg {
    display: block;
    height: 1.5rem;
    width: auto;
  }
`

const Navigator = styled.div`
  margin: -0.9375rem 0;
  display: flex;
  flex-direction: column;
  &:nth-of-type(2) {
    margin-top: 2rem;
  }
  &:nth-of-type(3) {
    margin-top: auto;
  }
`

const Item1 = styled.a`
  display: block;
  font-size: 1.125rem;
  letter-spacing: 0.03em;
  font-family: forum, serif;
  padding: 0.9375rem 0;
  line-height: 1;
`

const Item2 = styled.a`
  display: block;
  font-size: 0.8125rem;
  line-height: 1.125rem;
  padding: 0.5rem 0;
`

const LogoContainer = styled.a`
  display: block;
  margin-right: auto;
  position: absolute;
  right: var(--page-margin);
  bottom: var(--page-margin);
  svg {
    width: 4.5rem;
    height: auto;
    display: block;
  }
`
const Copyright = styled.div`
  font-family: forum, serif;
  font-size: 0.8125rem;
  letter-spacing: 0.03em;
  margin-top: 2.5rem;
`

type MenuProps = {
  open: boolean
}
export const Menu: FunctionComponent<MenuProps> = ({open}) => {
  return <Outer className={classNames({open})}>
    <SnsContainer>
      <SnsItem>
        <SvgXIcon />
      </SnsItem>
      <SnsItem>
        <SvgInstagramIcon />
      </SnsItem>
    </SnsContainer>
    <Navigator>
      <Item1 href="/shop/products">PRODUCT</Item1>
      <Item1 href="/shop/information_categories/topics">TOPICS</Item1>
      <Item1 href="/shop/faq">FAQ</Item1>
      <Item1 href="/shop/contact">CONTACT</Item1>
      <Item1 href="/shop/customers/sign_in">LOGIN</Item1>
      <Item1 href="/shop/cart">CART(0)</Item1>
    </Navigator>
    <Navigator>
      <Item2 href="/shop/law_info">会社概要</Item2>
      <Item2 href="/shop/guide">ご利用ガイド</Item2>
      <Item2 href="/shop/customer_term">利用規約</Item2>
      <Item2 href="/shop/pages/rental_terms">カロニアレンタルサービス利用規約</Item2>
      <Item2 href="/shop/law_info">特定商取引法に基づく表記</Item2>
      <Item2 href="/shop/privacy">プライバシーポリシー</Item2>
    </Navigator>
    <Copyright>© TRIANGLE&CO.</Copyright>
    <LogoContainer href="/">
      <SvgLogo />
    </LogoContainer>
  </Outer>
}